<div class="row cont-login login-company2">
  <div
    class="col-md-6 pd-forms-login2 pd-forms-login text-center no-display-movil"
  >
    <img class="img-company" src="../../assets/images/logo-white2.png" />
    <!--<div class="spacer-100"></div>-->
    <h1 class="color-white-company"><b>BIENVENIDO</b></h1>
    <p class="color-white p-login">
      Te damos la bienvenida a la mejor red para solicitar servicios de
      acarreos, mudanzas y logística en Panamá. Ingresa y disfruta de las
      soluciones que ofrece Kamgus
    </p>
  </div>
  <div class="col-md-6  cont-white .pd-forms-login2 pd-forms-login">
    <form
      class="form-horizontal form-material"
      [formGroup]="formData"
      *ngIf="formData"
    >
      <!--     <img src="../../assets/images/logo-kamgus.png" class="logo-kamgus"> -->
      <!-- <h3 class="box-title m-b-20">Ingresar</h3> -->
      <h4 class="text-center-ingresar-company">Ingresar</h4>
      <br /><br />
      <div class="alert alert-danger" *ngIf="failedLogin">
        Usuario y/o contraseña incorrecta
      </div>
      <div class="form-group">
        <div class="col-xs-12">
          <label for="" class="label-login__company">Usuario-Email </label>
          <input
            class="input-login-company2 | form-control input-register"
            type="text"
            required="true"
            placeholder="Email *"
            formControlName="user"
          />
        </div>
        <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
          <div *ngIf="f.user.errors.required">* El email es requerido</div>
        </div>
        <div *ngIf="submitted && f.user.errors" class="invalid-feedback">
          <div *ngIf="f.user.errors.email">
            * No es un formato de correo válido
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="col-xs-12">
          <label for="" class="label-login__company">Contraseña</label>
          <show-hide-password
            class="btn_ojo"
            size="sm"
            icon="empty"
            btnStyle="secondary"
            [btnOutline]="true"
          >
            <input
              class="input-login-company2 | form-control input-register"
              type="password"
              required="true"
              placeholder="Contraseña *"
              formControlName="pass"
            />
          </show-hide-password>
        </div>
        <div *ngIf="submitted && f.pass.errors" class="invalid-feedback">
          <div *ngIf="f.pass.errors.required">
            * La constraseña es requerida
          </div>
        </div>
      </div>
        <div class="row">

          <div class="col-xs-12 col-md-12 boxremember">
            <div class="checkbox checkbox-info pull-left p-t-0">
              <input
                id="checkbox-signup"
                type="checkbox"
                class="filled-in chk-col-light-blue"
              />
              <label for="checkbox-signup" class="text-Recuerdame">
                Recuerdame
              </label>
            </div>
          </div>
          <div class="col-xs-12 col-md-12 mb-4">
            <a
            href="javascript:void(0)"
            id="to-recover"
            class="text-dark text-olvide"
            ><i class="fa fa-lock m-r-5"></i> Olvidé mi contraseña</a
          >
          </div>
        </div>
       
      <div class="form-group text-center">
        <div class="col-xs-12 p-b-20">
          <button
            class="btn btn-block btn-rounded | btn-blue"
            type="submit"
            (click)="login()"
          >
            ENTRAR
          </button>
        </div>
        <div class="col-xs-12 p-b-20">
          <button
            class="btn btn-block btn-warning btn-rounded"
            routerLink="/register-company"
            (click)="login()"
          >
            REGISTRARME
          </button>
        </div>
      </div>

      <div class="textRegistrate">
          
        <a href=""
          ><b>Tengo problemas con mi acceso</b></a>
      
        
        <br />
        <br />       
      </div>

    </form>
  </div>
</div>

<app-AlertSwal></app-AlertSwal>