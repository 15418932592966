import { Injectable, OnInit } from "@angular/core";
import { SharedService } from "./shared.service";
import jwt_decode from 'jwt-decode';
import { t } from "@angular/core/src/render3";
import { Observable } from 'rxjs';
@Injectable()
export class SidebarService {
  menu: any = [];
  role: string;
  user: any;

  constructor(private sharedService: SharedService) {
    // codigo anterior
    this.role = this.sharedService.getRole();
    console.log(this.role);
    // // console.log("ROL -----", this.rol);
    // // this.user = sharedService.decodeJwt();
    // // this.rol = this.user.rol;
    if (this.role === 'Administrador') {
      this.menu = [
        {
          titulo: "Panel Administrativo",
          icon: "mdi mdi-home",
          submenu: [],
          url: "/dashboard",
        },
        {
          titulo: "Servicios",
          icon: "mdi mdi-car",
          url: "",
          submenu: [
            { titulo: "Nuevo servicio", url: "/services/point-to-point" },
            { titulo: "Multi punto", url: "/services/multi-point" },
            { titulo: "Servicios Activos", url:"/admin/active-services" },
            { titulo: "Servicios programados", url: "/admin/scheduled-services" },
            { titulo: "Historial", url: "/admin/service-history" }
            /* { titulo: 'Compare precios', url: '/services/compare-princing' }, */
          ],
        },
        // {
        //   titulo: "Historial",
        //   icon: "mdi mdi-history",
        //   url: "",
        //   submenu: [
        //     { titulo: "Servicios activos", url: "/history/active-services" },
        //     {
        //       titulo: "Servicios programados",
        //       url: "/history/schedule-services",
        //     },
        //     {
        //       titulo: "Historial de Servicios",
        //       url: "/history/finished-services",
        //     },
        //   ],
        // },
        {
          titulo: "Gestión de pagos",
          icon: "mdi mdi-coin",
          url: "",
          submenu: [
            { titulo: "Consolidados", url: "/payment-center/payment-summary" },
            {
              titulo: "Pendientes por pagar",
              url: "/payment-center/pending-payments",
            },
            {
              titulo: "Pendientes por recibir",
              url: "/payment-center/payments-to-receive",
            },
          ],
        },
        {
          titulo: "Billetera",
          icon: "mdi mdi-credit-card",
          url: "/wallet/method-payment",
          /* submenu: [
            { titulo: 'Métodos de pago', url: '/wallet/method-payment' },
            { titulo: 'Facturación', url: '/wallet/billing' }
          ] */
        },
        {
          titulo: "Usuarios",
          icon: "mdi mdi-account-outline",
          url: "/users",
          submenu: [],
        },
        /* {
          titulo: 'Referidos',
          icon: 'mdi mdi-account-star',
          url: '/referrals/referrals-types'
        },*/
        {
          titulo: "Kamgus Store",
          icon: "mdi mdi-store",
          url: "/store/products",
          /*submenu: [
            { titulo: 'Productos', url: '/store/products' },
            { titulo: 'Tienda', url: '/store/stores' },
            { titulo: 'Entregas', url: '/store/deliveries' }
          ]*/
        },
        {
          titulo: "Carga consolidada",
          icon: "mdi mdi-truck",
          url: "/massive-load/products",
          /*submenu: [
            { titulo: 'Productos', url: '/massive-load/products' },
            { titulo: 'Tiendas', url: '/massive-load/stores' },
            { titulo: 'Seguimientos', url: '/massive-load/follows' }
          ]*/
        },
        {
          titulo: "Soporte",
          icon: "mdi mdi-headphones",
          url: "/support/",
          /*submenu: [
            { titulo: 'Soporte', url: '/support/' },
            { titulo: 'Preguntas', url: '/support/faq' }
          ]*/
        },
        /*  {
          titulo: 'Preguntas frecuentes',
          icon: 'mdi mdi-comment-question-outline',
          url: '',
          submenu: []
        }, */
        {
          titulo: "Configuraciones",
          icon: "mdi mdi-settings",
          url: "/admin/settings",
          submenu: [],
        },
        {
          titulo: "Errores",
          icon: "mdi mdi-bug",
          url: "/",
          submenu: [],
        },
        {
          titulo: "Administración",
          icon: "mdi mdi-bulletin-board",
          url: "",
          submenu: [
            { titulo: "Roles", url: "/admin" },
            { titulo: "Licencias", url: "/admin/license" },
            { titulo: "Usuarios", url: "/admin/users" },
          ],
        },
      ];
    } 
    
    if (this.role === 'Cliente') {
      this.menu = [
        {
          titulo: "Servicios",
          icon: "mdi mdi-car",
          url: "",
          submenu: [
            { titulo: "Nuevo servicio", url: "/" },
            { titulo: "Servicios activos", url: "/history/active-services" },
            //{ titulo: 'Servicios programados', url: '/history/schedule-services' },
            {
              titulo: "Historial de servicios",
              url: "/history/finished-services",
            },
          ],
        },
        {
          titulo: "Billetera",
          icon: "mdi mdi-credit-card",
          url: "/wallet/method-payment",
          /* submenu: [
            { titulo: 'Métodos de pago', url: '/wallet/method-payment' },
            { titulo: 'Facturación', url: '/wallet/billing' }
          ] */
        },
        /*{
          titulo: 'Referidos',
          icon: 'mdi mdi-account-star',
          url: '/referrals/referrals-types'
        },*/

        {
          titulo: "Soporte",
          icon: "mdi mdi-headphones",
          url: "/support/",
          /* submenu: [
            { titulo: 'Soporte', url: '/support/' },
            { titulo: 'Preguntas', url: '/support/faq' }
          ]*/
        },
      ];
    } 
    
    if(this.role === 'Empresa') {
      this.menu = [
        {
          titulo: "Panel Administrativo",
          icon: "mdi mdi-home",
          submenu: [],
          url: "/dashboard",
        },
        {
          titulo: "Servicios",
          icon: "mdi mdi-car",
          url: "",
          submenu: [
            { titulo: "Multi punto", url: "/services/multi-point" },
            // {
            //   titulo: "Servicios activos",
            //   url: "/history/active-services-company",
            // },
            /*{ titulo: 'Servicios programados', url: '/history/schedule-services' },*/
            // {
            //   titulo: "Historial de Servicios",
            //   url: "/history/finished-services-company",
            // },
            { titulo: "Servicios Activos", url:"/admin/active-services" },
            { titulo: "Servicios programados", url: "/admin/scheduled-services" },
            { titulo: "Historial", url: "/admin/service-history" }
          ],
          /*submenu: [
            { titulo: 'Nuevo servicio', url: '/services/point-to-point' },
            { titulo: 'Multi punto', url: '/services/multi-point' },
            { titulo: 'Compare precios', url: '/services/compare-princing' }, 
          ]*/
        },
        /*{
          titulo: 'Historial',
          icon: 'mdi mdi-history',
          url: '',
          submenu: [
            { titulo: 'Servicios activos', url: '/history/active-services' },
            { titulo: 'Servicios programados', url: '/history/schedule-services' },
            { titulo: 'Historial de Servicios', url: '/history/finished-services' }, 
          ]
        },*/
        {
          titulo: "Gestión de pagos",
          icon: "mdi mdi-coin",
          url: "",
          submenu: [
            { titulo: "Consolidados", url: "/payment-center/payment-summary" },
            {
              titulo: "Pendientes por pagar",
              url: "/payment-center/pending-payments",
            },
            {
              titulo: "Pendientes por recibir",
              url: "/payment-center/payments-to-receive",
            },
          ],
        },
        {
          titulo: "Billetera",
          icon: "mdi mdi-credit-card",
          url: "/wallet/method-payment",
          /* submenu: [
            { titulo: 'Métodos de pago', url: '/wallet/method-payment' },
            { titulo: 'Facturación', url: '/wallet/billing' }
          ] */
        },
        {
          titulo: "Usuarios",
          icon: "mdi mdi-account-outline",
          url: "/users",
          submenu: [],
        },
        /*{
          titulo: 'Referidos',
          icon: 'mdi mdi-account-star',
          url: '/referrals/referrals-types'
        },*/
        {
          titulo: "Kamgus Store",
          icon: "mdi mdi-store",
          url: "/store/products",
          /*submenu: [
            { titulo: 'Articulos', url: '/store/products' },
            { titulo: 'Tienda', url: '/store/stores' },
            { titulo: 'Pedidos', url: '/store/deliveries' }
          ]*/
        },
        {
          titulo: "Carga consolidada",
          icon: "mdi mdi-truck",
          url: "/massive-load/products",
          /*submenu: [
            { titulo: 'Productos', url: '/massive-load/products' },
            { titulo: 'Tiendas', url: '/massive-load/stores' },
            { titulo: 'Seguimientos', url: '/massive-load/follows' }
          ]*/
        },
        {
          titulo: "Soporte",
          icon: "mdi mdi-headphones",
          url: "/support/",
          /*submenu: [
            { titulo: 'Soporte', url: '/support/' },
            { titulo: 'Preguntas', url: '/support/faq' }
          ]*/
        },
        /* {
          titulo: 'Configuraciones',
          icon: 'mdi mdi-settings',
          url: '/',
          submenu: []
        },*/
        /* {
          titulo: 'Errores',
          icon: 'mdi mdi-bug',
          url: '/',
          submenu: []
        },  */
      ];
    }


  }


}

/*this.rol = this.sharedService.decodeJwt();
    this.rol = this.rol.rol;
    console.log( this.rol );*/
