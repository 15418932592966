import { HttpClient } from "@angular/common/http";
import { environment } from "../../../environments/environment";
import { tap } from "rxjs/operators";
var apikamgus_base_url = environment.apikamgus_base_url;
var UsuarioService = /** @class */ (function () {
    function UsuarioService(http) {
        this.http = http;
    }
    Object.defineProperty(UsuarioService.prototype, "token", {
        get: function () {
            return 'Bearer ' + JSON.parse(localStorage.getItem('token')) || '';
        },
        enumerable: true,
        configurable: true
    });
    Object.defineProperty(UsuarioService.prototype, "options", {
        get: function () {
            return {
                headers: {
                    'Authorization': this.token
                }
            };
        },
        enumerable: true,
        configurable: true
    });
    UsuarioService.prototype.crearUsuario = function (formData) {
        console.log("creando usuario");
    };
    UsuarioService.prototype.getAllUsers = function (totalElements, role, status, fechaini, fechafin) {
        var arr = [];
        if (role) {
            arr.push("&role=" + role);
        }
        if (status) {
            arr.push("&status=" + status);
        }
        if (fechaini) {
            arr.push("&initial_date=" + fechaini);
        }
        if (fechafin) {
            arr.push("&final_date=" + fechafin);
        }
        var url = apikamgus_base_url + "/users?perPage=" + totalElements;
        for (var index = 0; index < arr.length; index++) {
            url += arr[index];
        }
        console.log(url);
        return this.http.get(url, this.options).pipe(tap(function (resp) {
            return resp;
        }));
    };
    UsuarioService.prototype.getRoles = function () {
        var url = apikamgus_base_url + "/roles";
        return this.http.get(url, this.options).pipe(tap(function (resp) {
            return resp;
        }));
    };
    /** Registrar nuevo usuario desde Administrador */
    UsuarioService.prototype.crearUser = function (params) {
        return this.http.post(environment.crearUser, params, this.options);
    };
    return UsuarioService;
}());
export { UsuarioService };
