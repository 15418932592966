import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

import { environment } from "../../../environments/environment";
import { AllUsersInterface } from '../../interfaces/AllUsersInterface';
import { tap } from "rxjs/operators";
import { RoleInterface } from '../../interfaces/RoleInterface';

const apikamgus_base_url = environment.apikamgus_base_url;

@Injectable()
// providedIn: 'root'
export class UsuarioService {

  constructor(
    public http: HttpClient
  ) {}

  get token(): string {
    return 'Bearer ' + JSON.parse(localStorage.getItem('token')) || '';
  }

  get options() {
    return {
      headers: {
        'Authorization': this.token
      }
    }
  }

  crearUsuario(formData: any) {
    console.log("creando usuario");
  }
  
  getAllUsers(totalElements: number, role:string, status:string, fechaini:string, fechafin:string){

    let arr = [];

    if(role){
      arr.push(`&role=${role}`)
    }

    if(status){
      arr.push(`&status=${status}`);
    }

    if(fechaini){
      arr.push(`&initial_date=${fechaini}`)
    }

    if(fechafin){
      arr.push(`&final_date=${fechafin}`)
    }

    let url = `${apikamgus_base_url}/users?perPage=${totalElements}`;

    for (let index = 0; index < arr.length; index++) {
    
      url += arr[index];
      
    }

    console.log(url);

    return this.http.get<AllUsersInterface>(url, this.options).pipe(
      tap((resp) => {
        return resp;
      })
    );

  }

  getRoles(){

    let url = `${apikamgus_base_url}/roles`;

    return this.http.get<RoleInterface>(url, this.options).pipe(
      tap((resp) => {
        return resp;
      })
    );
    
  }
  /** Registrar nuevo usuario desde Administrador */
  crearUser(params) {   
    return this.http.post(environment.crearUser, params, this.options);
  }
}
