import { HttpParams } from "@angular/common/http";
import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import * as bcrypt from "bcryptjs";
import { ApiService } from "../services/api/api.service";
import { LoginService } from "../services/login/login.service";
import { StorageService } from "../services/storage/storage.service";
import { Continue } from '../interfaces/ValidatorInterface';
declare function init_plugins();
@Component({
  selector: "app-register",
  templateUrl: "./register.component.html",
  styleUrls: ["./login/login.component.css"],
  providers: [ApiService],
})
export class RegisterComponent implements OnInit {

  //formularios para registrar paso 1 y 2
  formData: FormGroup;
  formDataEmail: FormGroup;

  //detectar si hace submit o no
  submitted = false;
  failedLogin = false;
  typeUser = "user";
  errorMessage;
  registerOK = false;
  licenseId = "1";
  step = 1;

  emailRegistrado = false;
  celularRegistrado = false;

  isLoading:boolean = false;

  constructor(
    private formBuilder: FormBuilder,
    private loginService: LoginService,
    private storageService: StorageService,
    private route: Router,
    private _api: ApiService,
    private router: ActivatedRoute
  ) {}

  ngOnInit() {
    init_plugins();
    this.loadForm();
    if (localStorage.getItem("user") || localStorage.getItem("user") !== null) {
      this.route.navigate(["/dashboard"]);
    }
    this.validateLicence();
  }

  validateLicence() {
    if (
      this.router.queryParams["_value"] &&
      this.router.queryParams["_value"].licenseKey
    ) {
      this.licenseId = this.router.queryParams["_value"].licenseKey;
    }
    this._api.licences().subscribe((lic) => {
      lic["licences"].forEach((element) => {
        if (element.id === this.licenseId) {
          this.licenseId = element.id;
        }
      });
    });
    console.log(this.licenseId);
  }

  loadForm() {
    this.formData = this.formBuilder.group({
      nombre: ["", [Validators.required]],
      celular: ["", [Validators.required]],
      documento_entidad: [0, [Validators.required]],
      password: ["", [Validators.required]],
      retypePassword: ["", [Validators.required]],
      terms: ["", [Validators.required]],
      company: ["", []],
    });
    this.formDataEmail = this.formBuilder.group({
      email: ["", [Validators.required, Validators.email]],
      reTypeEmail: ["", [Validators.required, Validators.email]],
    });
  }

  // send() {
  //   this.submitted = true;

  //   if (!this.formData.valid) {
  //     return;
  //   }
  //   const request = this.updateModel(this.formData.value);
  //   console.log(request);
  //   this.loginService.register(request).subscribe(
  //     (response) => {
  //       console.log("response", response);
  //       this.createLicense(response["id_user"]);
  //     },
  //     (err) => {
  //       console.log("err", err);
  //       this.errorMessage = err;
  //     }
  //   );
  //   console.log(this.formData.value);
  // }

  updateModel(form: any) {
    const params = new HttpParams()
      .set("email", this.formDataEmail.value.email)
      .set("nombre", this.typeUser === "company" ? form.company : form.nombre)
      .set("celular", form.celular)
      .set("documento_entidad", form.documento_entidad)
      .set("password", form.password)
      .set("rol", this.typeUser === "company" ? "5" : "2");
    return params;
  }

  get f() {
    return this.formData.controls;
  }

  get g() {
    return this.formDataEmail.controls;
  }

  selectUser($event) {
    this.typeUser = $event;
    if (this.typeUser === "company") {
      this.formData.get("company").setValidators(Validators.required);
      this.formData.get("company").updateValueAndValidity();
    } else {
      this.formData.get("company").clearValidators();
      this.formData.get("company").updateValueAndValidity();
    }
  }

  // createLicense(id_user: string) {
  //   const params = new HttpParams()
  //     .set("id_user", id_user)
  //     .set("id_licence", this.licenseId);
  //   this._api.activateFreeLicence(params).subscribe(
  //     (licence) => {
  //       this.createCustomer(id_user);
  //       this.registerOK = true;
  //       this.route.navigate(["/thankyou-page-company"]);
  //     },
  //     (err) => {
  //       this.errorMessage = err;
  //     }
  //   );
  // }

  // createCustomer(id_user: string) {
  //   const params = new HttpParams()
  //     .set("firstName", this.formData.value.nombre)
  //     .set("lastName", null)
  //     .set("phone", this.formData.value.celular)
  //     .set("email", this.formData.value.email)
  //     .set("idusuario", id_user)
  //     .set("id", this.formData.value.documento_entidad);
  //   this._api.genericPost("/createCustomer", params).subscribe(
  //     (respo) => {
  //       console.log("Customer creado...", respo);
  //     },
  //     (err) => {
  //       console.log("error create customerId", err);
  //     }
  //   );
  // }
  /*API NUEVO*/
  // crearUsuarioCompany() {
  //   this.submitted = true;

  //   // const password = this.formData.value.password;
  //   // let passwordHash = bcrypt.hashSync(password, 10);

  //   this.loginService
  //     .crearUsuario({
  //       // RegistroForm: {
  //       //   nombre: "empresaxyz",
  //       //   apellidos: "betin",
  //       //   celular: "12345678",
  //       //   password: "123456789",
  //       //   email: "empresaxyz@gmail.com",
  //       //   pais_id: "176",
  //       //   documento_entidad: "",
  //       //   url_foto: "",
  //       //   licencia_foto: "",
  //       //   tipo_documento_id: "1",
  //       //   rol: this.typeUser == "5",
  //       // },
  //       company: {
  //         nombre_empresa: this.formData.value.company,
  //         nombre_contacto: this.formData.value.nombre,
  //         telefono: this.formData.value.celular,
  //       },
  //       user: {
  //         email: this.formDataEmail.value.email,
  //         password: this.formData.value.password,
  //       },
  //     })
  //     .subscribe((respuesta) => {
  //       console.log("Usuario Creado");
  //       this.registerOK = true;
  //       this.route.navigate(["/thankyou-page-company"]);
  //     });
  // }

  next(step) {
    
    this.isLoading = true;
    const params = new HttpParams()
    .set("email", 'prebakamgus@gmail.com')
    .set("telefono", this.formData.value.celular)

    console.log(this.formData);
    this.submitted = true;
    if (step === 1) {
      if (!this.formData.valid) {
        this.isLoading = false;
        return;
      }
       /**** Agregado */
       this._api
       .validateUser(params)
       .subscribe(
         (data: Continue) => {
           this.step += 1;
           this.isLoading = false;
         },
         (err: any) => {
           this.celularRegistrado = true;
           this.isLoading = false;
           console.log(err);
         }
       );


      // this.step += 1;
      // this.isLoading = false;
    }
    /*if( step === 2 ) {
      this.step += 1;
    }*/
    //if( step === 3 ) {
    if (step === 2) {
      this.isLoading = true;
      const params_email = new HttpParams()
      .set("email", this.formDataEmail.value.email)
      .set("telefono", this.formData.value.celular)

      if (!this.formDataEmail.valid) {
        this.isLoading = false;
        return;
      } else if (this.g.email.value !== this.g.reTypeEmail.value) {
        this.isLoading = false;
        return;
      } else {
        //this.send();
        // this.crearUsuarioCompany();

        this._api
       .validateUser(params_email)
       .subscribe(
         (data: Continue) => {
           this.step += 1;
           this.createUserCompany();
           this.isLoading = false;
         },
         (err: any) => {
           this.emailRegistrado = true;
           this.isLoading = false;
           console.log(err);
         }
       );


        // this.isLoading = false;
        // this.createUserCompany();
      }
    }
  }


  //nueva api (apikamgus)
  createUserCompany(){
    this.isLoading = true;
    this.submitted = true;

    const params = new HttpParams()
    .set("nombre_empresa", this.formData.value.company)
    .set("nombre_contacto", this.formData.value.nombre)
    .set("telefono", this.formData.value.celular)
    .set("email", this.formDataEmail.value.email)
    .set("password", this.formData.value.password)
    .set("password_confirmation",  this.formData.value.password);
    
    this.loginService.registerCompanyUser(params).subscribe(
      (data) => {
        // console.log("Usuario Creado");
        this.isLoading = false;
        this.registerOK = true;
        this.route.navigate(["/thankyou-page-company"]);
      },
      (err) => {
        console.log(err);
      }
    );

  }
}
